<template>
  <div class="mx-10 mt-8 sm:mx-5 w-full sm:mb-9">
    <portal to="navbar-title">
      <h2 class="text-2xl xl:text-xl sm:text-base">Mes révélations</h2>
    </portal>
    <Popup v-show="showModal" @close="showModal = false">
      <h2 class="text-2xl font-bold py-2 font-main" slot="title">
        Récapitulatif de demande
      </h2>
      <p
        class="text-base font-normal text-promy-gray-250 first-letter:capitalize"
        slot="body"
      >
        êtes-vous sûr de vouloir annuler cette demande
      </p>
      <div slot="buttons" class="flex space-x-2 mt-5">
        <pro-button btn="primary" @click="handleCancelRevelation">
          <span class="py-1 px-3 first-letter:capitalize">confirmez</span>
        </pro-button>
        <pro-button btn="secondary" @click="showModal = false">
          <span class="py-1 px-3 first-letter:capitalize">annulez</span>
        </pro-button>
      </div>
    </Popup>
    <button
      class="p-4 rounded-lg w-fit bg-promy-blue-100 hover:opacity-70 sm:mb-4"
      @click="
        () => {
          $router.push({ name: 'revelations' })
        }
      "
      v-tooltip.right="{ content: 'Retour à la liste', classes: classes }"
    >
      <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9999 6.99997H4.41394L9.70694 1.70697L8.29294 0.292969L0.585938 7.99997L8.29294 15.707L9.70694 14.293L4.41394 8.99997H18.9999V6.99997Z"
          fill="#10AAAF"
        />
      </svg>
    </button>
    <h2
      class="font-bold text-2xl sm:mb-5 sm:text-base md:text-xl lg:text-xl sm:ml-2 text-promy-dark-purple my-5 sm:my-0"
    >
      Récapitulatif de demande
    </h2>
    <div
      class="grid grid-cols-5 md:grid-cols-1 lg:grid-cols-1 bg-promy-blue-70 rounded-md"
    >
      <div class="col-span-2 bg-white rounded-lg p-9">
        <div class="flex flex-col">
          <div class="flex space-x-3">
            <ProIcon
              name="marker"
              color="#10AAAF"
              class="!h-7 !w-7 sm:!h-6 sm:!w-5 mt-1"
            />
            <div>
              <h4 class="font-main font-bold text-xl sm:text-sm">
                {{ revelation.adresse }}
              </h4>
              <p class="font-normal text-base sm:text-sm">
                {{ revelation.departement }},
                {{ revelation.region }}
              </p>
              <p
                class="mt-5 md:!mt-1 lg:!mt-1"
                v-if="revelation.status && revelation.status === 'active'"
              >
                <ProIcon name="ellipse" color="#F4C417" class="!h-2 !w-2" />
                <span
                  v-if="revelation.status && revelation.status === 'active'"
                  class="font-medium text-base sm:text-sm text-promy-actions-warning"
                >
                  En cours
                </span>
              </p>
              <p
                class="mt-5 md:!mt-1 lg:!mt-1"
                v-if="revelation.status && revelation.status === 'finish'"
              >
                <ProIcon name="ellipse" color="#1EA133" class="!h-2 !w-2" />
                <span
                  class="font-medium text-base sm:text-sm text-promy-actions-success"
                >
                  Terminée
                </span>
              </p>
              <p
                class="mt-5 md:!mt-1 lg:!mt-1"
                v-if="revelation.status && revelation.status === 'canceled'"
              >
                <ProIcon name="ellipse" color="#E84646" class="!h-2 !w-2" />
                <span
                  class="font-medium text-base sm:text-sm text-promy-actions-danger"
                >
                  Annulée
                </span>
              </p>
            </div>
          </div>
          <div class="flex space-x-3 mt-12 md:mt-6 lg:mt-6">
            <ProIcon
              name="calendar"
              color="#10AAAF"
              class="!h-7 !w-7 mt-1 sm:!h-6 sm:!w-5 mt-1"
            />
            <div>
              <h4 class="font-main font-bold text-xl sm:text-sm">
                Date de création
              </h4>
              <p class="font-medium text-base text-promy-gray-200 sm:text-sm">
                {{ revelation.created_at | formatDate }}
              </p>
            </div>
          </div>
          <div class="flex flex-col space-y-3 md:space-y-5 lg:space-y-5 mt-11">
            <button
              class="w-5/6 md:w-full lg:w-full xl:w-11/12 sm:text-base bg-promy-green-350 px-11 py-5 sm:p-4 rounded-lg text-white font-semibold hover:bg-promy-green-300 disabled:bg-promy-gray-100 disabled:text-promy-gray-200"
              @click="editRevaltion(revelation.id)"
            >
              Modifiez les informations
            </button>
            <button
              @click="showModal = true"
              :disabled="revelation.status && revelation.status === 'canceled'"
              class="w-5/6 md:w-full lg:w-full xl:w-11/12 sm:text-base bg-promy-blue-50 px-8 py-5 sm:p-4 rounded-lg text-promy-green-350 font-semibold hover:bg-promy-blue-100 disabled:bg-promy-gray-100 disabled:text-promy-gray-200"
            >
              Annulez la demande
            </button>
          </div>
        </div>
      </div>
      <div v-if="!is_revelation_still_fetching" class="recap-container">
        <h3 class="font-bold text-2xl sm:text-sm">Récapitulatif</h3>

        <div class="general-recap">
          <div>
            <div class="mb-2 leading-8 -space-y-3">
              <p class="font-semibold">
                {{ revelationDetails.fullname }}
              </p>
              <p class="text-promy-gray-200 capitalize">
                {{ revelationDetails.profile }} (Qui êtes vous ?)
              </p>
            </div>
            <p class="leading-6">{{ revelationDetails.email }}</p>
            <p class="leading-6">
              {{ revelationDetails.phone_number }}
            </p>
          </div>
          <ul class="font-semibold leading-6 list-disc list-inside">
            <li>Résidence {{ revelationDetails.residence_type }}</li>
            <li>
              Superficie de terrain
              {{ revelationDetails.superficie }}m²
            </li>
            <li>
              {{ revelationDetails.pieces }} (hors cuisine, salle de bain et WC)
            </li>
            <li>État du bien : {{ revelationDetails.good_state }}</li>
          </ul>
        </div>
        <hr class="border-promy-gray-200" />
        <div class="additional-recap">
          <div class="space-y-3">
            <div>
              <p class="font-semibold">Type du bien</p>
              <p class="text-promy-gray-200">
                {{
                  revelation.informations.type_bien === 'Autre'
                    ? revelation.informations.other_type_bien
                    : revelation.informations.type_bien
                }}
              </p>
            </div>
            <div class="space-y-2">
              <p class="font-semibold">Souhaitez-vous vendre votre bien ?</p>
              <p class="text-promy-gray-200">
                {{ revelationDetails.on_sale }}
              </p>
            </div>
            <div>
              <p class="font-semibold">
                Êtes-vous déjà en contact avec un promoteur/constructeur ?
              </p>
              <p class="text-promy-gray-200">
                {{ revelation.informations.is_contact_with_promoteur }}
              </p>
            </div>
            <div class="space-y-2">
              <p class="font-semibold">
                Avez-vous déjà fait estimer ce bien par un professionnel?
              </p>
              <p class="text-promy-gray-200">
                {{ revelationDetails.is_estimated }}
              </p>
            </div>
            <div>
              <p class="font-semibold">
                Êtes-vous propriétaire d'un autre bien?
              </p>
              <p class="text-promy-gray-200">
                {{ revelation.informations.has_other_bien }}
              </p>
            </div>
            <div v-if="revelation.informations.has_other_bien === 'Oui'">
              <p class="font-semibold">Est-ce un bien constructible ?</p>
              <p class="text-promy-gray-200">
                {{ revelation.informations.has_bien_constructible }}
              </p>
            </div>
            <div
              v-if="
                revelation.informations.has_other_bien === 'Oui' &&
                revelation.informations.has_bien_constructible === 'Oui'
              "
            >
              <p class="font-semibold">
                Description du bien que vous voulez vendre
              </p>
              <p class="text-promy-gray-200">
                {{ revelation.informations.autre_bien_constructible }}
              </p>
            </div>
          </div>
          <div class="space-y-6">
            <div>
              <p class="font-semibold">Selon vous, combien vaut votre bien ?</p>
              <p class="text-promy-gray-200">
                {{ revelation.informations.estimated_price | formatEUR }}
              </p>
            </div>
            <div>
              <p class="font-semibold">Projets de vente à proximité</p>
              <p class="text-promy-gray-200">
                {{ revelation.informations.distance_projet_promoteur_autour }}
              </p>
            </div>
            <div>
              <p class="font-semibold">
                Avez-vous des voisins intéressés par un projet de vente ?
              </p>
              <ul
                v-if="
                  revelation.informations.has_neighbor_or_otherOwner === 'Oui'
                "
              >
                <li
                  v-for="(voisin, index) in revelation.informations
                    .neighbors_or_otherOwners"
                  :key="index"
                >
                  <span class="text-promy-gray-200">
                    {{ voisin.nom }} {{ voisin.prenom }}
                  </span>
                  ({{ voisin.adresse.label }})
                </li>
              </ul>
              <p v-else class="text-promy-gray-200">Non</p>
            </div>
            <div>
              <p class="font-semibold">
                Souhaitez-vous apporter des informations complémentaires ?
              </p>
              <p class="text-promy-gray-200">
                {{ revelationDetails.additional_information }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/fr'
export default {
  filters: {
    formatDate: function (value) {
      moment.locale('fr')
      if (!value) return ''
      return moment(value).format('MMMM Do YYYY, h:mm a')
    },
  },
  data() {
    return {
      revelation: {},
      is_revelation_still_fetching: true,
      showModal: false,
      revelationDetails: {},
      classes: [
        'text-promy-green-350',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow',
        'text-xs',
        'bg-white',
        'mb-2',
        'ml-2'
      ],
    }
  },
  created() {
    this.getRevelation(this.$route.params.id)
  },
  methods: {
    editRevaltion(id) {
      this.$router.push({
        name: 'revelationStep1',
        params: { id_revelation: id },
      })
    },
    getRevelation(id) {
      this.$http
        .get(`/grand-publics/reveler-potentiels/${id}`)
        .then((response) => {
          this.revelation = response.data.revelerPotentiel

          let civilite = ''

          if (this.revelation.civilite === 'Autre') {
            civilite += this.revelation.informations.other_civilite
          }

          if (this.revelation.civilite !== 'Autre') {
            if (this.revelation.civilite === 'Monsieur') {
              civilite += 'M.'
            } else {
              civilite += 'Mme'
            }
          }
          let profile = ''
          if (this.revelation.informations.label_profile === 'autre') {
            profile = this.revelation.informations.other_label_profile
          } else {
            profile = this.revelation.informations.label_profile
          }

          let good_state =
            this.revelation.informations.etat_bien === 'autre'
              ? this.revelation.informations.other_etat_bien
              : this.revelation.informations.etat_bien

          this.revelationDetails = {
            fullname: `${civilite} ${this.revelation.prenom} ${this.revelation.nom}`,
            profile: profile,
            email: this.revelation.email,
            phone_number: this.revelation.tel,
            residence_type: this.revelation.informations.residence
              ? this.revelation.informations.residence.toLowerCase()
              : 'N.C',
            superficie: this.revelation.informations.superficie_totale || 'N.C',
            pieces: this.revelation.informations.pieces || 'N.C',
            good_state: good_state || 'N.C',
            additional_information:
              this.revelation.informations.more_informations || 'Non',
            on_sale: this.revelation.informations.on_sale,
            is_estimated: this.revelation.informations.is_estimated || 'N.C',
          }

          this.is_revelation_still_fetching = false
        })
    },
    handleCancelRevelation() {
      this.$http
        .post(`/grand-publics/reveler-potentiels/${this.revelation.id}/cancel`)
        .then((res) => {
          this.toast(
            'Récapitulatif de demande',
            'La demande a été annulée avec succès',
            'success',
          )
          this.revelation.status = 'canceled'
        })
        .catch((err) =>
          this.toast(
            'Récapitulatif de demande',
            "Une erreur s'est produite réessayez plus tard",
            'error',
          ),
        )
      this.showModal = false
    },
  },
}
</script>
<style scoped>
.recap-container {
  @apply col-span-3 bg-promy-blue-70 py-7 px-8 text-promy-dark-purple rounded-r-md;
}
.general-recap {
  @apply full-hd:w-[82%] my-5 flex sm:flex-col sm:space-y-4 items-baseline justify-between md:text-sm;
}
.additional-recap {
  @apply mt-5 leading-5 grid grid-cols-2 sm:grid-cols-1 gap-x-10 lg:gap-x-40 md:gap-x-4 sm:gap-x-0 sm:gap-y-4 md:text-sm;
}
</style>
